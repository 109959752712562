// extracted by mini-css-extract-plugin
export var bigDataContainer = "index-module--bigDataContainer--lkZBd";
export var contentList = "index-module--contentList--v3EES";
export var contentListItem = "index-module--contentListItem--bqlcL";
export var desc = "index-module--desc--vtucb";
export var number = "index-module--number--FIsAk";
export var numberBox = "index-module--numberBox--iyObb";
export var numberContent = "index-module--numberContent---svRa";
export var numberMain = "index-module--numberMain--ONojn";
export var numberWidth108 = "index-module--numberWidth108--fvX0I";
export var numberWidth54 = "index-module--numberWidth54--N4r+T";
export var suffix = "index-module--suffix--vXtlf";
export var unit = "index-module--unit--cdOR6";