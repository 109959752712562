import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import { Languages } from 'i18n/config'
import { isPc } from '../../utils/utils'

/**
 * 页面中板块的 Header 组件，包括 模块主题，模块描述?。
 * @param {Object} param0
 * @param {String} param0.title - 模块主题。支持传入 html 标签
 * @param {String} [param0.desc] - 模块描述
 * @param {String} [param0.className] - 支持传入类名
 * @param {String} [param0.theme] - 模块主题；默认是黑色字体。 支持传入 "white" ，白色字体。
 * @param {String} param0.titleBg - 主题背景图片
 */
const SectionHeader: React.FC = ({
  className,
  theme,
  title,
  desc,
  descInfo,
  pClassName,
  title_prev,
  titleBg,
  bgWidth,
  sortTitle,
  lang = Languages.CN,
}: any) => {
  const isEn = lang === Languages.EN

  const mbTitle = sortTitle ? sortTitle : title

  return (
    <>
      <div className={styles.SectionHeader}>
        <div className={classnames(`text-center relative ${isEn ? '' : 'leading-[80px]'} lg:leading-[60px]`)}>
          <span
            className={classnames(
              styles.textBg,
              'font-medium text-[#04CB94] opacity-[0.06] text-[40px] lg:text-[80px] block',
            )}
          >
            {titleBg}
          </span>
          <h2
            className={`w-full absolute text-[#2C2C45] font-medium text-[20px] lg:text-[30px] ${
              !isEn ? 'leading-[4rem]' : 'leading-[4rem]' // 移动端长英文名称导致的问题
            } lg:leading-[60px] top-0`}
          >
            <span className="hidden lg:inline">
              {title_prev}&nbsp;{title}
            </span>
            <span className="inline lg:hidden">{mbTitle}</span>
          </h2>
        </div>
        {desc ? (
          <p className={classnames('hidden lg:block text-[#2C2C45] text-[14px] mt-[10px] text-center')}>{desc}</p>
        ) : null}
        {descInfo && (
          <p
            className={classnames(
              styles.descInfo,
              'hidden lg:block text-[#2C2C45] mt-[6px] text-center leading-[14px]',
            )}
          >
            {descInfo}
          </p>
        )}
      </div>
    </>
  )
}

export default SectionHeader as any
