import React from 'react'
import classnames from 'classnames'
import Button, { ButtonGroup } from 'components/Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import * as styles from './index.module.less'
import { Languages } from 'i18n/config'
interface propContentItem {
  title: string
  logo: string
  desc_list: string[]
  buttonLink: string
  buttonText: string
  buttonText_pc: string
}

interface buttonItem {
  text?: string
  href?: string
  ghost?: boolean
  btnType?: string
  className?: string
  target?: string
  url?: any
}

interface propsType {
  content: propContentItem[]
  bg: string
  bg_mb: string
  link_logo_mb?: string
  buttons?: buttonItem[]
  lang?: Languages
}
// 限制英文文本长度解决文字占在图片上的问题
const enStyle = {
  // '01': 'lg:w-[180px]',
  '01': '',
  '12': 'lg:w-[130px]',
  '21': 'lg:w-[200px]',
}

const HomeSeries: React.FC<propsType> = ({ content: list, bg, bg_mb, link_logo_mb, buttons, lang }: any) => {
  const isEn = lang === Languages.EN

  return (
    <>
      <div className={classnames('hidden lg:block', styles.homeSeries)} style={{ backgroundImage: `url(${bg})` }}>
        <div className="w-[1200px] my-0 mx-[auto] flex justify-end">
          <div className={styles.contentContainer}>
            <div className={styles.contentList}>
              {list.map((item, index) => (
                <div className={classnames(styles.seriesItem, styles[`seriesItem_0${index + 1}`])} key={index}>
                  <div className={classnames(styles.seriesContent)}>
                    <h3 className={styles.title}>{item.title}</h3>
                    <ul className={styles.descList}>
                      {item.desc_list.map((listItem, j) => (
                        <li
                          className={`${styles.descItem} ${
                            isEn && enStyle['' + index + j] ? enStyle['' + index + j] : ''
                          }`}
                          key={listItem.title}
                        >
                          {listItem?.title}
                          {listItem?.title_one}
                          <br />
                          {listItem?.title_two}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <img className={styles.imgBG} src={item.logo?.publicURL ?? item.logo} alt={item?.alt || ''} />
                </div>
              ))}
            </div>
            <ButtonGroup className={classnames(styles.buttonsGroup)}>
              {buttons?.map((el: buttonItem, i: number) => (
                <Button
                  key={i}
                  {...el}
                  ghost={el.ghost}
                  href={el.href}
                  className={classnames(el.className, { 'lg:hidden': i === 1 && !isEn })}
                  target={el.target || '_blank'}
                  btnType={el.btnType}
                >
                  {el.text}
                </Button>
              ))}
              {buttons?.[1] && !isEn && (
                <ScanCodeConsultButton
                  className="lg:ml-[35px] hidden lg:inline-block"
                  ghost={true}
                  buttonText={buttons?.[1].text}
                  imgBottomText={buttons?.[1].url ? '微信扫码 即刻咨询' : ''}
                  qrcode={buttons?.[1].url?.publicURL ?? buttons?.[1].url}
                />
              )}
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div className={classnames('block lg:hidden', styles.homeSeries_Mb)} style={{ backgroundImage: `url(${bg_mb})` }}>
        <ul className={styles.contentList}>
          {list.map((item, index) => (
            <li key={index} className={styles.seriesItem} onClick={() => window.open(item?.buttonLink)}>
              <div className={styles.mainContentItem}>
                {/* <img src={item.logo_mb?.publicURL ?? item.logo_mb} className="w-[51px]" alt={item?.alt || ''}/> */}
                <div className={styles.title}>
                  <span className={`${isEn ? 'w-[14.2rem]' : ''}`}>{item.title}</span>
                  <p className={styles.button}>
                    <span className={styles.buttonText}>{item.buttonText}</span>
                    <span>
                      <img src={link_logo_mb.publicURL ?? link_logo_mb} />
                    </span>
                  </p>
                </div>
              </div>
              <div className={styles.detailContent}>
                {item.desc_list.map((listItem, index) => (
                  <p className={styles.detail} key={index}>
                    {listItem?.title} {listItem?.title_two}
                  </p>
                ))}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default HomeSeries
