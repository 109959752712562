import React, { useState } from 'react'
import Video from 'components/Base/Video'
import classnames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './index.module.less'

interface videoItem {
  poster?: any
  /** alt描述 */
  alt?: string
  videoUrl?: string
}
interface videoProp {
  videoUrl?: string
  playBtn?: any
  videoPoster?: any
  /** 图片描述 */
  alt?: string
  videoGroup?: videoItem[]
  onPlay: (url) => void
}

const VideoIndex: React.FC<videoProp> = ({ videoUrl, videoPoster, alt = '', videoGroup, playBtn, onPlay }) => {
  const [current, setCurrent] = useState(0)
  return (
    <>
      <div className={classnames('hidden lg:flex pt-[71px] pb-[42px] justify-between w-[1200px] mx-[auto]')}>
        <div className={classnames(styles.videoPicture)}>
          <img src={videoPoster?.publicURL ?? videoPoster} alt={alt} />
          <div
            className={classnames(styles.videoBtn, { '!flex': current === 0 })}
            onMouseEnter={() => setCurrent(0)}
            onClick={() => onPlay(videoUrl)}
          >
            <img src={playBtn} />
          </div>
        </div>
        <ul className={styles.pictureGroup}>
          {videoGroup &&
            videoGroup.map((video, i) => (
              <li key={i} onMouseEnter={() => setCurrent(i + 1)} onMouseLeave={() => setCurrent(0)}>
                <img src={video?.poster.publicURL ?? video?.poster} alt={video?.alt || ''} />
                <div className={classnames(styles.videoBtn)} onClick={() => onPlay(video?.videoUrl)}>
                  <img className={classnames('!w-[44px] hidden', { '!block': current === i + 1 })} src={playBtn} />
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className={classnames('block lg:hidden', styles.videoIndexMb)}>
        <Swiper
          className="h-[235px]"
          pagination={{ clickable: true }}
          autoHeight={false}
          spaceBetween={10}
          autoplay={{
            delay: 10000,
          }}
        >
          <SwiperSlide>
            <div className="relative">
              <img src={videoPoster?.publicURL ?? videoPoster} />
              <div className={classnames(styles.videoBtn)} onClick={() => onPlay(videoUrl)}>
                <img className={classnames('w-[36px] h-[36px]')} src={playBtn} />
              </div>
            </div>
          </SwiperSlide>
          {videoGroup?.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={classnames('relative')}>
                <img src={item.poster.publicURL ?? item.poster} alt={item?.alt || ''} />
                <div
                  className={classnames(styles.videoBtn)}
                  style={{ background: 'rgba(0,0,0,0.3)' }}
                  onClick={() => onPlay(item?.videoUrl)}
                >
                  <img className={classnames('w-[36px] h-[36px]')} src={playBtn} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default VideoIndex
