import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface listItem {
  title?: string
  logo?: any
  alt?: string
}
interface propsType {
  className?: string
  list?: listItem[]
}
const SensorsQualications: React.FC<propsType> = ({ className, list }) => {
  return (
    <>
      <div className={classnames('hidden lg:block', styles.contentContainer, className)}>
        <Swiper className={styles.swiper} slidesPerView={6}>
          {list?.map((listItem, index) => (
            <SwiperSlide key={index}>
              <img src={listItem?.logo?.publicURL ?? listItem?.logo} alt={listItem?.alt || ''} />
              <p className={styles.logoTitle}>{listItem?.title}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={classnames('block lg:hidden', styles.contentContainerMb)}>
        <Swiper className={styles.swiper} slidesPerView={6}>
          <SwiperSlide>
            <ul className={styles.swiperList}>
              {list?.map((listItem, i) => (
                <li key={i} className={styles.listItem}>
                  <img src={listItem?.logo?.publicURL ?? listItem?.logo} />
                  <p className={styles.logoTitle}>{listItem?.title}</p>
                </li>
              ))}
            </ul>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

export default SensorsQualications
