import React, {useState, useEffect} from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import classnames from 'classnames';
import * as styles from './index.module.less';
interface propsType {
  className?: string
  delay?: number
  suffix?: string
  start?: number
  end?: number
  duration?: number
  prefix?: string
}
const AnimationNumber: React.FC<propsType> = ({className, start= 0, end = 100, suffix = '', duration= 0.8, prefix = ''}) => {
  const [visible, setVisible] = useState(true)
  const [viewTimes, setViewTimes] = useState(0);
  function onChange(isVisible) {
    // 进入页面且没有出现在可视窗口中
    if (viewTimes === 0 && !isVisible) {
      setVisible(isVisible)
      setViewTimes(viewTimes + 1)
    }
    
    // 进入页面后，将这个模块滚动到可是窗口中后，数字滚动展示，状态不在改变
    if (viewTimes === 1 && isVisible) {
      setVisible(isVisible)
    }
   
}
  return (
    <VisibilitySensor onChange={onChange}>
      {visible ? <CountUp className={classnames(className)} end={end} suffix={suffix} start={start} prefix={prefix} duration={duration} /> : <span>'hidden'</span>}
    </VisibilitySensor>
  )
}

export default AnimationNumber;