import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button, { ButtonGroup, ButtonProps, ButtonType } from 'components/Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface listItem {
  title?: string
  en_title?: string
  link?: string
  logo?: any
  logo_mb?: any
  alt?: string
}

interface buttonItem {
  text?: string
  href?: string
  ghost?: boolean
  btnType?: string
  className?: string
  target?: string
  url?: any
}
interface PropsType {
  className?: string
  list?: listItem[]
  buttons?: buttonItem[]
  canClick?: boolean
  lang?: string
}
const ServiceAndProducts: React.FC<PropsType> = ({ className, list, buttons, canClick = true, lang }) => {
  const isEn = lang === 'en'

  return (
    <>
      <div className={classnames(styles.contentContainer, className, 'hidden lg:block')}>
        <ul className={styles.list}>
          {list?.map((item, i) => (
            <li className={styles.listItem} key={i} onClick={() => canClick && window.open(item.link)}>
              <img width={284} src={item.logo?.publicURL ?? item.logo} alt={item?.alt || ''} />
              <p className={styles.title}>{item.title}</p>
              <p className={styles.en_title}>{item.en_title}</p>
            </li>
          ))}
        </ul>
        <ButtonGroup className={classnames(styles.buttonsGroup)}>
          {buttons?.map((el: buttonItem, i: number) =>
            el.text ? (
              <Button
                key={i}
                {...el}
                ghost={el.ghost}
                href={el.href}
                className={classnames(el.className, { 'lg:hidden': !isEn && i === 1 })}
                target={el.target || '_blank'}
                btnType={el.btnType}
              >
                {el.text}
              </Button>
            ) : null,
          )}
          {buttons?.[1] && !isEn && (
            <ScanCodeConsultButton
              className="lg:ml-[35px] hidden lg:inline-block"
              ghost={true}
              buttonText={buttons?.[1].text}
              imgBottomText="微信扫码 即刻咨询"
              qrcode={buttons?.[1].url?.publicURL ?? buttons?.[1].url}
            />
          )}
        </ButtonGroup>
      </div>
      <div className={classnames('block lg:hidden', styles.contentContainerMb)}>
        <Swiper
          className={styles.swiperStyle}
          slidesPerView="auto"
          centeredSlides={true}
          pagination={{ type: 'bullets' }}
          spaceBetween={30}
        >
          {list?.map((item, i) => (
            <SwiperSlide key={i} onClick={() => canClick && window.open(item.link)}>
              <div className={styles.listItem}>
                <p>
                  <img src={item.logo_mb?.publicURL ?? item.logo_mb} alt={item?.alt || ''} />
                </p>
                <p className={styles.title}>{item.title}</p>
                <p className={styles.en_title}>{item.en_title}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* 移动端代码 */}
        <ButtonGroup className={classnames(styles.buttonsGroup)}>
          {buttons?.map((el: buttonItem, i: number) =>
            el.text ? (
              <Button
                key={i}
                {...el}
                ghost={el.ghost}
                href={el.href}
                className={el.className}
                target={el.target || '_blank'}
                btnType={el.btnType}
              >
                {el.text}
              </Button>
            ) : null,
          )}
        </ButtonGroup>
      </div>
    </>
  )
}

export default ServiceAndProducts
