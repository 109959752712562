import React, { useEffect, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'

import SectionHeader from 'components/SectionHeaderNew'
import { Swiper, SwiperSlide } from 'swiper/react'
import Banner from 'components/Home/BannerNew'
import BannerBottom from 'components/Home/BannerBottom'

import HomeSeries from 'components/Home/HomeSeries'
import VideoIndex from 'components/Home/VideoContainer'
import BigDataContent from 'components/Home/BigDataContent'
import ServiceAndProducts from 'components/Home/ServiceAndProducts'
import DigitalOperate from 'components/Home/DigitalOperate'
import IndustryTrustChoice from 'components/Home/IndustryTrustChoice'
import ResearchAndShare from 'components/Home/ResearchAndShare'
import SensorsQualications from 'components/Home/SensorsQualications'
import VideoPlayer from 'components/VideoPlayer'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import PageBottomCard from 'components/PageBottomCardNew'
import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'
import { isPc } from 'utils/utils'
import '../styles/swiper-pagination.less'

import BannerImage from 'components/Home/BannerImage'
import { fetchBanner } from 'api/index'
import { fetchLibraryHotRecommend, fetchLibraryLatestList } from 'api/doc'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
interface HomeProps {
  className?: string
}
const Home: React.FC<HomeProps> = (props) => {
  return (
    <Layout {...props} showLangDropdown={true} useH1={false}>
      <Seo
        lang={'zh'}
        title={`神策数据｜大数据分析与营销科技解决方案服务商`}
        description="神策数据提供企业数字化运营方案与数据驱动咨询服务，赋能企业全域用户经营、全链路分析和全场景营销能力，加速企业数字化转型，实现持续增长。"
        keywords="数据分析,数字化营销,数字化运营,全域营销,企业解决方案"
        saTitle="首页｜神策数据官网"
      />
      <HomeState />
    </Layout>
  )
}

interface HomeStateProps {
  className?: string
}
export const HomeState: React.FC<HomeStateProps> = () => {
  const curVideo = useRef<{ playVideo: () => void }>()
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const {
    homeNewYaml: { second_part, third_part, number_banner, four_part, five_part, six_part, seven_part, eight_part },
    allHomeBannerNewYaml: { nodes },
  } = useStaticQuery(graphql`
    query {
      homeNewYaml {
        second_part {
          buttons {
            btnType
            ghost
            href
            text
            url
          }
          content_details {
            buttonLink
            buttonText
            buttonText_pc
            desc_list {
              title
            }
            title
            logo {
              publicURL
            }
            alt
          }
          desc
          title
          title_w
          title_bg
          linkBtnMb
          sectionBg {
            publicURL
          }
          sectionBgMb
        }
        third_part {
          videoGroup {
            videoUrl
            alt
            poster
          }
          videoPoster
          alt
          videoBtn
          videoUrl
        }
        number_banner {
          className
          desc
          suffix
          number
          unit
          logo
        }
        four_part {
          desc
          title_prev
          title_w
          title
          buttons {
            btnType
            ghost
            href
            text
            url
          }
          title_bg
          list {
            en_title
            title
            link
            logo {
              publicURL
            }
            logo_mb {
              publicURL
            }
            alt
          }
        }
        five_part {
          title
          title_w
          desc
          buttons {
            btnType
            href
            text
            url
          }
          list {
            bg_number
            number_color
            en_title
            title
            logo
            logoMb
            alt
            next
          }
          title_bg
        }
        six_part {
          title
          desc
          desc_info
          title_w
          brandListBg
          brandListModal
          list_one {
            desc_01
            desc_02
            desc_num
            isText
            text
            textImg
          }
          map_bg_mb
          title_bg
        }
        seven_part {
          linkLogoMb
          buttons {
            btnType
            href
            text
          }
          desc
          title
          title_w
          title_bg
        }
        eight_part {
          title
          title_bg
          list {
            title
            logo
            alt
          }
        }
      }
      allHomeBannerNewYaml(filter: { hidden: { ne: true } }) {
        nodes {
          id
          desc
          enTitle
          hidden
          leftButton
          leftButtonLink
          rightButton
          rightButtonLink
          link
          isTitle
          mb {
            publicURL
          }
          pc {
            publicURL
          }
          title
        }
      }
    }
  `)

  const [bannerArr, setBannerArr] = useState<any[]>(nodes)
  const [hotRecommend, setHotRecommend] = useState<any>({})
  const [latest, setLatest] = useState<any[]>([])
  useEffect(() => {
    async function getBannerDetail() {
      try {
        const res = await fetchBanner()
        const newArr = [...nodes]
        if (res.status === 200) {
          for (let obj of res.data) {
            if (obj?.bannerOrder === 2 && !obj?.hidden) {
              newArr.splice(newArr.length, 0, {
                pc: obj?.pcBannerUrl,
                mb: obj?.mobBannerUrl,
                link: obj?.link,
              })
            } else if (obj?.bannerOrder === 4 && !obj?.hidden) {
              newArr.push({
                pc: obj?.pcBannerUrl,
                mb: obj?.mobBannerUrl,
                link: obj?.link,
              })
            }
          }
          setBannerArr(newArr)
        }
      } catch (err) {
        console.log(err)
      }
    }
    getBannerDetail()
    fetchLibraryLatestList()
      .then(({ data }) => {
        setHotRecommend(data?.hotTarget || {})
        setLatest(data?.latest || [])
      })
      .then((err) => {
        console.log('内容获取失败')
      })
  }, [])

  const handlePlay = (url) => {
    setVideoUrl(url)
    setTimeout(() => {
      curVideo?.current?.playVideo()
    }, 100)
  }

  return (
    <main className={classnames(styles.Home, 'page-home')}>
      <div className={classnames('home-banner')}>
        <Swiper
          pagination={{ clickable: true }}
          autoHeight={false}
          allowTouchMove={!isPc()}
          autoplay={{
            delay: 10000,
          }}
        >
          {bannerArr?.map((item: any, i) => {
            if (item?.title === '虚拟内容') {
              return null
            }
            if (item?.pc) {
              return (
                <SwiperSlide key={i}>
                  <BannerImage
                    src={item.pc?.publicURL || item.pc}
                    mbSrc={item.mb.publicURL || item.mb}
                    href={item?.link}
                    leftButton={item?.leftButton}
                    leftButtonLink={item?.leftButtonLink}
                    rightButton={item?.rightButton}
                    rightButtonLink={item?.rightButtonLink}
                  />
                </SwiperSlide>
              )
            }
            return (
              <SwiperSlide key={item.id}>
                <Banner
                  className={classnames(styles.index_banner)}
                  title={item?.title}
                  desc={item?.desc}
                  intro={item?.enTitle}
                  h1tag={item?.isTitle}
                  buttons={[
                    {
                      text: item?.leftButton,
                      href: item?.leftButtonLink,
                      btnType: 'primary',
                    },
                    {
                      text: item?.rightButton,
                      ghost: true,
                      btnType: 'primary',
                      onClick: () => {
                        setVideoUrl(item?.rightButtonLink)
                        setTimeout(() => {
                          curVideo?.current?.playVideo()
                        }, 100)
                      },
                    },
                  ]}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
        {/* 行业 */}
        <BannerBottom />
      </div>
      {/* 数字化经营能力构建 方法论系列 */}
      <div className={classnames(styles.products)}>
        <SectionHeader
          title={second_part?.title}
          desc={second_part?.desc}
          titleBg={second_part?.title_bg}
          bgWidth={second_part?.title_w}
        />
        <HomeSeries
          content={second_part?.content_details}
          bg={second_part?.sectionBg?.publicURL ?? second_part?.sectionBg}
          bg_mb={second_part?.sectionBgMb?.publicURL ?? second_part?.sectionBgMb}
          link_logo_mb={second_part?.linkBtnMb?.publicURL ?? second_part?.linkBtnMb}
          buttons={second_part?.buttons}
        />
      </div>
      {/* 视频 */}
      <div className="relative h-[125px] lg:h-[auto] video-index">
        <VideoIndex
          playBtn={third_part?.videoBtn?.publicURL ?? third_part?.videoBtn}
          videoUrl={third_part?.videoUrl}
          videoPoster={third_part?.videoPoster}
          alt={third_part?.alt || ''}
          videoGroup={third_part.videoGroup}
          onPlay={handlePlay}
        />
      </div>

      {/* 条形banner */}
      <div className={classnames(styles.bigdataBanner, 'hidden lg:block')}>
        <BigDataContent list={number_banner} className={styles.bigDataContent} />
      </div>
      {/* 适用于高绩效商业的 数字化产品与服务体系 */}
      <div className={classnames(styles.serviceAndProducts)}>
        <SectionHeader
          title={four_part?.title}
          title_prev={four_part?.title_prev}
          desc={four_part?.desc}
          titleBg={four_part?.title_bg}
          bgWidth={four_part?.title_w}
        />
        <ServiceAndProducts
          className={styles.serviceAndProductsContent}
          list={four_part?.list}
          buttons={four_part?.buttons}
        />
      </div>
      {/* 如何成功构建 数字化经营能力 */}
      <div className={styles.digitalOperation}>
        <SectionHeader
          title={five_part?.title}
          desc={five_part?.desc}
          titleBg={five_part?.title_bg}
          bgWidth={five_part?.title_w}
        />
        <DigitalOperate
          className={styles.digitalOperationContents}
          list={five_part?.list}
          buttons={five_part?.buttons}
        />
      </div>
      {/* 全球行业领导者信赖之选 */}
      <div className={styles.industryTrustChoice}>
        <SectionHeader
          title={six_part?.title}
          desc={six_part?.desc}
          descInfo={six_part?.desc_info}
          titleBg={six_part?.title_bg}
          bgWidth={six_part?.title_w}
        />
        <IndustryTrustChoice
          className={styles.industryChoiceMainContent}
          list_one={six_part?.list_one}
          map_bg_mb={six_part?.map_bg_mb}
          brand_bg={six_part?.brandListBg}
          brand_modal={six_part?.brandListModal}
        />
      </div>
      {/* 行业研究 & 实战分享  */}
      <div className={styles.researchAndShare}>
        <SectionHeader
          title={seven_part?.title}
          titleBg={seven_part?.title_bg}
          desc={seven_part?.desc}
          bgWidth={seven_part?.title_w}
        />
        <ResearchAndShare
          className={styles.researchAndShareContent}
          recommend={hotRecommend}
          list={latest}
          buttons={seven_part?.buttons}
          linkLogoMb={seven_part?.linkLogoMb?.publicURL ?? seven_part?.linkLogoMb}
        />
      </div>
      {/* 神策认证与资质证明备份 */}
      <div className="pt-[6rem] mb-[2.6rem] lg:mb-0 lg:pt-[110px]">
        <SectionHeader title={eight_part?.title} titleBg={eight_part?.title_bg} />
        <SensorsQualications className={styles.sensorsQualications} list={eight_part?.list} />
      </div>

      {/* @ts-ignore */}
      <PageBottomCard
        title="开启大数据分析与营销科技之旅！"
        desc="立即注册，和神策数据专家一起探讨数字化！"
        leftButtonText="体验 Demo"
        leftButtonHref="/demo/demo.html"
        rightButtonText="预约演示"
        rightButtonHref="/form/parade.html"
      />
      {videoUrl && (
        <VideoPlayer
          videoOpt={{
            sources: videoUrl,
          }}
          fuRef={curVideo}
          showLoadTip
          onClose={() => setVideoUrl(null)}
        />
      )}
    </main>
  )
}

export default Home
