import React from 'react'
import Button, { ButtonGroup, ButtonProps, ButtonType } from 'components/Base/Button'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface recommendItem {
  name?: string
  previewPicUrl?: any
  posterMb?: any
  desc?: string
  tagRespVoList?: tagProp[]
  objId?: string
  alt?: string
}

interface tagProp {
  tagObjId?: string
  tagName?: string
}

interface listItem {
  alt?: string
  previewPicUrl?: any
  name?: string
  objId?: string
}

interface buttonItem {
  text?: string
  href?: string
  ghost?: boolean
  btnType?: string
  className?: string
  target?: string
}

interface propsType {
  recommend?: recommendItem
  list?: listItem[]
  className?: string
  buttons?: buttonItem[]
  linkLogoMb?: any
}

export const WORDS_TO_URL = {
  通用行业: 'tong-yong',
  电子商务: 'dian-shang',
  互联网金融: 'jin-rong',
  企业服务: 'qi-fu',
  汽车: 'qi-che',
  保险: 'bao-xian',
  银行: 'yin-hang',
  证券基金: 'zheng-quan-ji-jin',
  游戏: 'you-xi',
  社交文娱: 'wen-yu',
  在线教育: 'jiao-yu',
  工具软件: 'gong-ju',
  本地生活: 'sheng-huo',
  营销自动化: 'ying-xiao-zi-dong-hua',
  精细化运营: 'jing-xi-hua-yun-ying',
  数字化转型: 'shu-zi-hua-zhuan-xing',
  用户运营: 'yong-hu-yun-ying',
  数据分析: 'shu-ju-fen-xi',
  广告投放: 'guang-gao-tou-fang',
  用户画像: 'yong-hu-hua-xiang',
  精准营销: 'jing-zhun-ying-xiao',
  数据治理: 'shu-ju-zhi-li',
  私域运营: 'si-yu-yun-ying',
  实践案例: 'shi-jian-an-li',
}

const ResearchAndShare: React.FC<propsType> = ({ recommend, list, buttons, linkLogoMb }) => {
  const handleClick = (id) => {
    window.open(`https://www.sensorsdata.cn/school/library/${id}`)
  }
  return (
    <>
      <div className={classnames('hidden lg:block', styles.mainContent)}>
        <div className={classnames(styles.contentContainer)}>
          <div className={styles.recommend}>
            <div className={styles.contentWrapper}>
              <img
                src={recommend?.previewPicUrl}
                alt={recommend?.alt || ''}
                onClick={() => handleClick(recommend?.objId)}
              />
              <p className={styles.recommendTitle}>{recommend?.name}</p>
              <p className={styles.recommendDesc}>{recommend?.desc}</p>
            </div>

            <ul className={styles.tags}>
              {recommend?.tagRespVoList?.map((tag, i) => (
                <li className={styles.tagItem} key={i}>
                  {tag?.tagName && WORDS_TO_URL[tag.tagName] ? (
                    <a href={`/school/library/${WORDS_TO_URL[tag.tagName]}`}>{tag?.tagName}</a>
                  ) : (
                    tag?.tagName
                  )}
                </li>
              ))}
            </ul>
          </div>
          <ul className={styles.newContentList}>
            {list?.map((newItem, i) => (
              <li className={styles.contentListItem} key={i} onClick={() => handleClick(newItem?.objId)}>
                <img src={newItem?.previewPicUrl} alt={newItem?.alt || newItem?.name || ''} />
                <p className={styles.newItemTitle}>{newItem?.name}</p>
              </li>
            ))}
          </ul>
        </div>

        <ButtonGroup className={classnames(styles.buttonsGroup)}>
          {buttons?.map((el: buttonItem, i: number) =>
            el.text ? (
              <Button
                key={i}
                {...el}
                ghost={el.ghost}
                href={el.href}
                className={el.className}
                target={el.target || '_blank'}
                btnType={el.btnType}
              >
                {el.text}
              </Button>
            ) : null,
          )}
        </ButtonGroup>
      </div>
      <div className={classnames('block lg:hidden', styles.contentContainerMb)}>
        <div className={styles.recommend}>
          <div className={styles.contentWrapper} onClick={() => handleClick(recommend?.objId)}>
            <img src={recommend?.previewPicUrl} alt={recommend?.alt || ''} />
            <p className={styles.recommendTitle}>{recommend?.name}</p>
            <p className={styles.recommendDesc}>{recommend?.desc}</p>
          </div>
          <ul className={styles.tags}>
            {recommend?.tagRespVoList?.map((tag, i) => (
              <li className={styles.tagItem} key={i}>
                {tag?.tagName && WORDS_TO_URL[tag.tagName] ? (
                  <a href={`/school/library/${WORDS_TO_URL[tag.tagName]}`}>{tag?.tagName}</a>
                ) : (
                  tag?.tagName
                )}
              </li>
            ))}
          </ul>
        </div>
        <ul className={styles.newContentList}>
          {list?.map((newItem, i) => (
            <li className={styles.contentListItem} key={i} onClick={() => handleClick(newItem?.objId)}>
              <p className={styles.newItemLogo}>
                <img src={newItem?.previewPicUrl} className="h-full" alt={newItem?.alt || newItem?.name || ''} />
              </p>
              <p className={styles.newItemTitle}>{newItem?.name}</p>
            </li>
          ))}
        </ul>
        <p className={styles.buttonStyle}>
          <a href={buttons?.[0]?.href}>
            <span className="mr-[8px] pt-[1px]"> {buttons?.[0].text}</span>
            <span>
              <img src={linkLogoMb} />
            </span>
          </a>
        </p>
      </div>
    </>
  )
}

export default ResearchAndShare
