// extracted by mini-css-extract-plugin
export var buttonStyle = "index-module--buttonStyle--8LQzy";
export var buttonsGroup = "index-module--buttonsGroup--NLsP+";
export var contentContainer = "index-module--contentContainer--G71VV";
export var contentContainerMb = "index-module--contentContainerMb--a7MQJ";
export var contentListItem = "index-module--contentListItem--sXMb6";
export var contentWrapper = "index-module--contentWrapper--A-BYL";
export var newContentList = "index-module--newContentList--bC1XZ";
export var newItemLogo = "index-module--newItemLogo--r-FwR";
export var newItemTitle = "index-module--newItemTitle--IlUT7";
export var recommend = "index-module--recommend--841gc";
export var recommendDesc = "index-module--recommendDesc--89z+5";
export var recommendTitle = "index-module--recommendTitle--dHNeZ";
export var tagItem = "index-module--tagItem--AL8ER";
export var tags = "index-module--tags--PcIsf";