// extracted by mini-css-extract-plugin
export var bgTransparent = "index-module--bgTransparent--18S8h";
export var button = "index-module--button--qZu4J";
export var buttonText = "index-module--buttonText--iiv4w";
export var buttonsGroup = "index-module--buttonsGroup--FcMHz";
export var contentContainer = "index-module--contentContainer--jWVf+";
export var contentList = "index-module--contentList--6SV7D";
export var descItem = "index-module--descItem--Ix1lV";
export var descList = "index-module--descList--iA8nv";
export var detail = "index-module--detail--i5GCX";
export var detailContent = "index-module--detailContent--UI2VT";
export var homeSeries = "index-module--homeSeries--HxZ-6";
export var homeSeries_Mb = "index-module--homeSeries_Mb--e5Ht8";
export var imgBG = "index-module--imgBG--PKYY1";
export var mainContentItem = "index-module--mainContentItem--Yz8FZ";
export var seriesContent = "index-module--seriesContent--M21yu";
export var seriesItem = "index-module--seriesItem--HUtVv";
export var seriesItem_01 = "index-module--seriesItem_01--NdrBO";
export var seriesItem_02 = "index-module--seriesItem_02--B-VrF";
export var seriesItem_03 = "index-module--seriesItem_03--akc0n";
export var title = "index-module--title--U09-M";