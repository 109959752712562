import fetch from './fetch'

const DOC_API = process.env.DOC_API
const EE_API = process.env.EE_API
// 所有行业数据同时带上标签集合[Menu数据]
export const fetchAllIndustry = () =>
  fetch({
    url: `${DOC_API}/v1/tags/documents/${process.env.STATIC_CATEGORY_ID}/category`,
  })

// 指标详情
export const fetchTarget = (objId) =>
  fetch({
    url: `${DOC_API}/v1/documents/${objId}`,
  })

// 指标搜索
export const searchTarget = (searchKey, pageIndex, perPage) => {
  return fetch({
    url: `${DOC_API}/v1/documents/${pageIndex}`,
    method: 'post',
    params: {
      perPage,
    },
    data: {
      searchKey,
    },
  })
}

//资源图书馆首页card
export const fetchAllLibrary = (contentTagObjId = '') =>
  fetch({
    url: `${DOC_API}/v2/documents/1?perPage=1000`,
    method: 'post',
    data: {
      categoryObjId: 'fce1810d-089a-11ed-b6f6-b8599fae21ba',
      contentCategoryId: '4da086da-0be5-11ed-b6f6-b8599fae21ba',
      tagObjId: contentTagObjId,
    },
  })
// 行业标签父级id category_id/tagCategory_id：fce1810d-089a-11ed-b6f6-b8599fae21ba
// 内容标签腹肌id contentCategoryId: 4da086da-0be5-11ed-b6f6-b8599fae21ba
// 资源图书馆资料详情
export const fetchLibraryDetail = (objId) =>
  fetch({
    url: `${DOC_API}/v2/document/fce1810d-089a-11ed-b6f6-b8599fae21ba/${objId}?contentCategoryId=4da086da-0be5-11ed-b6f6-b8599fae21ba`,
  })

// 资源图书馆猜你喜欢
export const fetchLibraryRecommend = () =>
  fetch({
    url: `${EE_API}/api/www/v1/blog/hot/target/fce1810d-089a-11ed-b6f6-b8599fae21ba`,
  })

// 资源图书馆 内容分类：所有类型
export const fetchLibraryTag = () =>
  fetch({
    url: `${DOC_API}/v2/tag/fce1810d-089a-11ed-b6f6-b8599fae21ba/category`,
  })
// 资源图书馆 标签：所有行业
export const fetchLibraryIndustryTag = () =>
  fetch({
    url: `${DOC_API}/v2/tag/4da086da-0be5-11ed-b6f6-b8599fae21ba/category`,
  })

//首页获取热门推荐第一条与最新更新数据两条
export const fetchLibraryLatestList = () =>
  fetch({
    url: `${DOC_API}/v2/documents/library/latest`,
  })
