import React, { useState, useRef, useEffect, useCallback } from 'react'
import * as styles from './index.module.less'
import classnames from 'classnames'
import expand from 'assets/images/home/expand_arrow.png'
import stow from 'assets/images/home/stow_arrow.png'
import { Languages } from 'i18n/config'
import { getIndustryList } from './fields'

interface IProps {
  /** 是否可以触发hover动效 */
  canHover?: boolean
  /** 语言 */
  lang?: Languages
}

const BannerBottom: React.FC<IProps> = ({ canHover = true, lang = Languages.CN }) => {
  const industryList = getIndustryList({ lang })
  const isEn = lang === Languages.EN
  const [cardHeight, setHeight] = useState(isEn ? 390 : 480)

  const refMb = useRef<HTMLUListElement>(null)

  const [detailShow, setDetailShow] = useState<any>({
    show0: true,
    show1: false,
    show2: false,
    show3: false,
  })

  const pc_content = () => {
    return (
      <div className={classnames(styles.industry)}>
        <ul className={classnames(styles.mainContent)}>
          {industryList.map((item) => (
            <li
              className={classnames({
                [styles.contentItem]: true,
                [item.style]: canHover,
              })}
              key={item.text}
            >
              <div className={styles.titleContent}>
                <img src={item.logo} alt={item?.alt || ''} />
                <h2 className={styles.contentText}>{item.text}</h2>
                <div className={styles.contentDesc}>{item.desc}</div>
              </div>
              <div className={styles.popoverContent}>
                <div className={styles.popoverText}>{item.text}</div>
                <div className={styles.popoverDesc}>{item.desc}</div>
                <div className={styles.industryClass}>
                  {item.details.map((detail) => (
                    <a className={styles.industryItem} href={detail.link} key={detail.text}>
                      <span className={styles.industryName}>{detail.text}</span>
                    </a>
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const handleTanggle = async (i) => {
    await setDetailShow({ ...detailShow, ['show' + i]: !detailShow[`show${i}`] })
    refMb?.current?.offsetHeight && setHeight(refMb?.current?.offsetHeight)
  }

  const mb_content = (canClick: boolean = true) => {
    return (
      <div className={classnames({ [styles.industry_mb]: true, [styles.en_mb]: isEn })}>
        <ul className={styles.mainContent} ref={refMb}>
          {industryList?.map((item, i) => (
            <li className={classnames(styles.conentItem)} key={item.text}>
              <div
                className={styles.mainContentItem}
                onClick={() => {
                  canClick && handleTanggle(i)
                }}
              >
                <img src={item.logo_mb} className="w-[79px]" alt={item?.alt || ''}/>
                <div className={styles.contentText}>
                  <h2 className={styles.contentTitle}>{item.text}</h2>
                  <p className={styles.contentDesc}>{item.desc}</p>
                </div>
                {/* 展开标签 */}
                {canClick && (
                  <div className="w-[17px] h-[9px]">
                    <img className="mt-[-10px]" src={detailShow?.[`show${i}`] ? expand : stow} />
                  </div>
                )}
              </div>
              {canClick && (
                <div
                  className={classnames(styles.detailList)}
                  style={{ display: detailShow?.[`show${i}`] ? 'flex' : 'none' }}
                >
                  {item.details?.map((detail, index) => (
                    <div
                      key={index}
                      className={styles.detailItem}
                      onClick={() => (window.location.href = detail?.link)}
                    >
                      <span className="text-[14px]">{detail?.text}</span>
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  return (
    <>
      <div className="hidden lg:block">{pc_content()}</div>
      <div className="block relative lg:hidden" style={{ height: `${cardHeight}px` }}>
        {mb_content(canHover)}
      </div>
    </>
  )
}

export default BannerBottom as any
