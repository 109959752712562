import React, { useEffect, useState } from 'react'
import { isPc } from 'utils/utils'
import AnimationNumber from 'components/AnimationNumber'
import Swipers, { Controller, Navigation } from 'swiper'
import Carousel from 'components/Carousel'

import 'swiper/swiper.less'
import 'swiper/components/navigation/navigation.less'
import 'swiper/components/pagination/pagination.less'

import * as styles from './index.module.less'
import classnames from 'classnames'
import { debounce } from 'lodash'

Swipers.use([Controller, Navigation])
interface listOneItem {
  isText?: Boolean
  text?: any
  textImg?: any
  desc_01?: string
  desc_num?: number
  desc_02?: string
}
interface PropsType {
  className?: string
  list_one?: any[]
  map_bg_mb?: string
  brand_bg?: any
  brand_modal?: any
}
const IndustryTrustChoice: React.FC<PropsType> = ({ className, list_one, brand_bg, brand_modal }) => {
  const [showMap, setShowMap] = useState(true)
  useEffect(() => {
    const fn = debounce(() => {
      setShowMap(isPc())
    }, 1000)
    addEventListener('resize', fn, false)
    return () => {
      removeEventListener('resize', fn, false)
    }
  }, [])
  return (
    <>
      <div
        className={classnames(
          styles.contentContainer,
          'lg:bg-center lg:bg-cover lg:bg-no-repeat bg-[#F9FAFC] lg:bg-[#fff] relative pb-[38px] pb-auto mt-[5.9rem] lg:mt-[53px] lg:h-[664px]',
        )}
        style={{ backgroundImage: showMap ? `url(${brand_bg?.publicURL ?? brand_bg})` : '' }}
      >
        {!!list_one?.length && (
          <ul className={classnames('hidden lg:flex mt-[53px] justify-between items-center lg:flex-1', className)}>
            {list_one?.map((item, i) => (
              <li key={i} className={styles.listOneItem}>
                <p className={styles.text}>
                  {item?.isText ? item?.text : <img src={item.textImg?.publicURL ?? item.textImg} />}
                </p>
                <p className={styles.animationNum}>
                  {item.desc_01}&nbsp;
                  <AnimationNumber end={item.desc_num} className={styles.number} />
                  &nbsp;
                  {item.desc_02}
                </p>
              </li>
            ))}
          </ul>
        )}
        <ul
          className={classnames(
            'flex justify-around items-center lg:hidden absolute top-[-7.4rem] left-0 right-0',
            styles.listOneMb,
          )}
        >
          {list_one?.map((item, i) => (
            <li key={i}>
              <div className={styles.numberContainer}>
                <span className={styles.number}>{item.desc_num}</span>
                <span className={styles.unit}>{item.desc_02}</span>
              </div>
              <div className={styles.desc}>{item.desc_01}</div>
            </li>
          ))}
        </ul>
        <Carousel brand_bg={brand_bg} brand_modal={brand_modal} />
      </div>
    </>
  )
}

export default IndustryTrustChoice
