import fetch from './fetch'
import qs from 'qs'
const SURVEY_API = process.env.SURVEY_API
const EE_API = process.env.EE_API
const INTERNATIONAL_API = process.env.INTERNATIONAL_API

// 上传接口
// data: {real_name, phone, code}
export const fetchUploadAliOssSts = (data) =>
  fetch({
    url: `${SURVEY_API}/upload/ali_oss_sts`,
    method: 'post',
    data: qs.stringify(data),
  })
export const fetchSurveyUpload = (data) =>
  fetch({
    url: `${SURVEY_API}/survey/upload`,
    method: 'post',
    data: qs.stringify(data),
  })

// 签约客户认证
// 通过邮箱后缀获取企业列表
export const getEnterprisesList = (data) =>
  fetch({
    withCredentials: true,
    url: `${EE_API}/api/www/v1/enterprises?emailSuffix=${data}`,
    method: 'get',
  })
// 获取用户企业认证信息
export const getUserCertification = () =>
  fetch({
    url: `${EE_API}/api/www/v1/user/enterprise/certification`,
    method: 'get',
    withCredentials: true,
  })
// 创建用户企业认证
export const postUserCertification = (data) =>
  fetch({
    url: `${EE_API}/api/www/v1/user/enterprise/certification`,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
  })
// 删除用户的企业认证
export const deleteUserCertification = () =>
  fetch({
    url: `${EE_API}/api/www/v1/user/enterprise/certification`,
    method: 'delete',
    withCredentials: true,
  })
// 激活用户的企业认证
export const patchUserCertification = (data) =>
  fetch({
    url: `${EE_API}/api/www/v1/user/enterprise/certification`,
    method: 'patch',
    withCredentials: true,
    data: qs.stringify(data),
  })

// 获取热门指标
export const getHotTagList = () =>
  fetch({
    url: `${EE_API}/api/www/v1/blog/hot/target`,
    method: 'GET',
  })

export const getIndustryList = () =>
  fetch({
    url: `${EE_API}/api/www/v1/blog/industries/target`,
    method: 'GET',
  })

// banner信息
export const fetchBanner = () => {
  return fetch({
    url: `${EE_API}/api/www/v1/homeBanner/ow`,
  })
}

// 获取用户企业认证信息 (学堂接口)
export const getUserCertificationNew = () =>
  fetch({
    url: `${EE_API}/api/school/v1/user/enterprise/certification`,
    method: 'get',
    withCredentials: true,
  })

// 获取解决方案聚合页视频播放量
export const getSolutionsVideoView = (index) => {
  return fetch({
    url: `${EE_API}/api/www/v1/video/view/count?contentIndex=${index}`,
    method: 'get',
  })
}

// 获取产品方案聚合页视频播放量和白皮书查看量
export const getProductView = (index) => {
  return fetch({
    url: `${EE_API}/api/www/v1/video/viewAndDownLoad/count?contentIndex=${index}`,
    method: 'get',
  })
}

// 解决方案聚合页订阅
export const fetchSolutionsSubscribe = (data) => {
  return fetch({
    url: `${EE_API}/api/www/v1/user/subscribe`,
    method: 'post',
    data: data,
    withCredentials: true,
  })
}

// 遊戲雲申請表單
export const postGameActivityUserInfo = (data) => {
  return fetch({
    url: `${EE_API}/api/www/v1/gameActivity`,
    method: 'post',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  })
}
// AWS 表单申请
export const getAWS = (data) => {
  return fetch({
    url: `${EE_API}/api/www/aws/sales/form`,
    method: 'post',
    withCredentials: true,
    data,
  })
}
// 国际站 AWS 表单申请
export const getAWSEn = (data) => {
  return fetch({
    url: `${INTERNATIONAL_API}/api/owe/sales/form/aws`,
    method: 'post',
    withCredentials: true,
    data,
  })
}
