import React from 'react';
import classnames from 'classnames';
import AnimationNumber from 'components/AnimationNumber';

import * as styles from './index.module.less';

interface listItemProps {
  logo?: any,
  number?: number,
  className: string,
  suffix?: string,
  unit?: string,
  desc?: string
}
interface propsType {
  className?:string
  list?: listItemProps[]
}
const BigDataContent: React.FC<propsType> = ({ className, list }) => {
  return <div className={classnames(styles.bigDataContainer, className)}>
    <ul className={styles.contentList}>
      {list?.map(item => (
        <li key={item.desc} className={styles.contentListItem}>
          <img className='w-[54px] h-[49px]' src={item.logo?.publicURL??item.logo} />
          <div className={styles.numberContent}>
            <div className={styles.numberMain}>
              <div className={styles.numberBox}>
                <AnimationNumber className={classnames(styles.number, styles[item.className])} end={item.number} />
                <span className={styles.suffix}>{item.suffix}</span>
              </div>
              <span className={styles.unit}>{item.unit}</span>
            </div>
            <p className={styles.desc}>{item.desc}</p>
          </div>
        </li>
      ))}
    </ul>
  </div>
}

export default BigDataContent