import React from 'react'
import Button, { ButtonGroup, ButtonProps, ButtonType } from 'components/Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import * as styles from './index.module.less'
import classnames from 'classnames'

interface listItem {
  title?: string
  en_title?: string
  logo?: any
  logoMb?: any
  next?: any
  bg_number?: string
  number_color?: string
  alt?: string
}

interface buttonItem {
  text?: string
  href?: string
  ghost?: boolean
  btnType?: string
  className?: string
  target?: string
  url?: any
}
interface PropsType {
  className?: string
  list?: listItem[]
  buttons?: buttonItem[]
}
const DigitalOperate: React.FC<PropsType> = ({ className, list, buttons }) => {
  // 是否有二维码
  const codeUrl = buttons?.[0].url?.publicURL ?? buttons?.[0]?.url

  // 英文第二个白底绿字按钮
  const ghostBtn = buttons?.[1]?.ghost ?? false

  return (
    <>
      <div className={classnames('hidden lg:block', styles.contentContainer, className)}>
        <ul className={styles.list}>
          {list?.map((item, i) => (
            <li className={styles.listItem} key={i}>
              <div className={styles.mainContent}>
                <img src={item.logo?.publicURL ?? item.logo} alt={item?.alt || ''} />
                <div className={styles.titleContent}>
                  <div className={styles.titleAll}>
                    <p className={styles.title}>{item.title}</p>
                    <p className={styles.en_title}>{item.en_title}</p>
                  </div>
                  <p className={styles.bg_number} style={{ color: item.number_color }}>
                    {item.bg_number}
                  </p>
                </div>
              </div>
              {item.next && (
                <p className={styles.next}>
                  <img src={item.next?.publicURL ?? item.next} />
                </p>
              )}
            </li>
          ))}
        </ul>
        <ButtonGroup className={classnames(styles.buttonsGroup)}>
          {!!codeUrl ? (
            <ScanCodeConsultButton buttonText={buttons?.[0]?.text} imgBottomText="微信扫码 即刻咨询" qrcode={codeUrl} />
          ) : (
            <Button
              {...buttons?.[0]}
              ghost={buttons?.[0].ghost}
              href={buttons?.[0].href}
              className={classnames(buttons?.[0].className)}
              target={buttons?.[0].target || '_blank'}
              btnType={buttons?.[0].btnType}
            >
              {buttons?.[0].text}
            </Button>
          )}
          {ghostBtn && (
            <Button
              {...buttons?.[1]}
              ghost={buttons?.[1].ghost}
              href={buttons?.[1].href}
              className={classnames(buttons?.[1].className)}
              target={buttons?.[1].target || '_blank'}
              btnType={buttons?.[1].btnType}
            >
              {buttons?.[1].text}
            </Button>
          )}
        </ButtonGroup>
      </div>
      <div className={classnames('block lg:hidden', styles.contentContainerMb)}>
        <ul className={styles.list}>
          {list?.map((item, i) => (
            <li key={i} className={styles.listItem}>
              <div className={styles.logo}>
                <img src={item.logoMb?.publicURL ?? item.logoMb} alt={item?.alt || ''} />
              </div>
              <div className={styles.titleContent}>
                <div className={styles.titleAll}>
                  <p className={styles.title}>{item.title}</p>
                  <p className={styles.en_title}>{item.en_title}</p>
                </div>
                <p className={styles.bg_number} style={{ color: item.number_color }}>
                  {item.bg_number}
                </p>
              </div>
            </li>
          ))}
        </ul>

        <ButtonGroup className={classnames(styles.buttonsGroup)}>
          {buttons?.map((el: buttonItem, i: number) =>
            el.text ? (
              <Button
                key={i}
                {...el}
                ghost={el.ghost}
                href={el.href}
                className={el.className}
                target={el.target || '_blank'}
                btnType={el.btnType}
              >
                {el.text}
              </Button>
            ) : null,
          )}
        </ButtonGroup>
      </div>
    </>
  )
}

export default DigitalOperate
