// extracted by mini-css-extract-plugin
export var bannerBottom01 = "index-module--bannerBottom01--Mh9WB";
export var bannerBottom02 = "index-module--bannerBottom02--Ivv2V";
export var bannerBottom03 = "index-module--bannerBottom03--s4m1E";
export var bannerBottom04 = "index-module--bannerBottom04--sq6-r";
export var conentItem = "index-module--conentItem--Lcf4E";
export var contentDesc = "index-module--contentDesc--d71XZ";
export var contentItem = "index-module--contentItem--8K750";
export var contentText = "index-module--contentText--b80+7";
export var contentTitle = "index-module--contentTitle--GhYiz";
export var detailItem = "index-module--detailItem--T7ClP";
export var detailList = "index-module--detailList--TaPlL";
export var en_mb = "index-module--en_mb--En+Wx";
export var industry = "index-module--industry--otF44";
export var industryClass = "index-module--industryClass--dztLI";
export var industryItem = "index-module--industryItem--Ag+Ub";
export var industryName = "index-module--industryName--nAYJ8";
export var industry_mb = "index-module--industry_mb--rFPpG";
export var mainContent = "index-module--mainContent--R14Ep";
export var mainContentItem = "index-module--mainContentItem--XYWoG";
export var popoverContent = "index-module--popoverContent--dwSrD";
export var popoverDesc = "index-module--popoverDesc--x9QNA";
export var popoverText = "index-module--popoverText--RWFen";
export var tangleActive = "index-module--tangleActive--ukBvb";
export var tangleNormal = "index-module--tangleNormal--1mkDH";
export var titleContent = "index-module--titleContent--SdG9l";