import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import defaultBg from 'assets/images/home/home_logo.png'

import * as styles from './index.module.less'
const Carousel: React.FC<any> = ({
  brand_modal,
  className,
  img,
  showMask = false,
  showWhiteMask = false,
  iconContain = false,
  itemClassName00,
  itemClassName01,
  brandModalClassName,
}) => {
  const customStyles: any = {
    '--image': img ? `url(${img?.publicURL ?? img})` : `url(${defaultBg})`,
    '--backgroundSize': iconContain ? 'contain' : 'cover',
  }

  return (
    <div className={classNames(styles.Carousel, className, 'lg:mt-[57px] pt-[4.7rem] lg:pt-0')} style={customStyles}>
      {brand_modal && (
        <img
          className={classNames('hidden lg:block', styles.brandModal, brandModalClassName)}
          src={brand_modal?.publicURL ?? brand_modal}
        />
      )}
      {showMask && <div className={styles.mask}></div>}
      {showWhiteMask && <div className={styles.whiteMask}></div>}
      <div className={styles.wrap}>
        <ul className={styles.slider}>
          <li className={classnames(styles.index_0, itemClassName00)}></li>
          <li className={classnames(styles.index_1, itemClassName01)}></li>
        </ul>
      </div>
    </div>
  )
}

export default Carousel
