import React from 'react'
import classnames from 'classnames'
import Button, { ButtonType } from 'components/Base/Button'
import * as styles from './index.module.less'

interface BannerImageProps {
  className?: string
  href?: string
  src?: string
  mbSrc?: string
  leftButton?: string
  leftButtonLink?: string
  rightButton?: string
  rightButtonLink?: string
}
const BannerImage: React.FC<BannerImageProps> = ({
  src,
  mbSrc,
  href,
  className,
  leftButtonLink,
  leftButton,
  rightButtonLink,
  rightButton,
}) => {
  return (
    <div className={classnames('h-[38rem] lg:h-[637px] relative', className)}>
      {/* pc 端 */}
      <a
        className={classnames('hidden lg:block w-full h-full text-center bg-center bg-cover bg-no-repeat')}
        href={href}
        target="_blank"
        style={{ backgroundImage: `url(${src})` }}
      />
      {/* mb 端 */}
      <a
        className={classnames('block lg:hidden w-full h-full text-center bg-center bg-cover bg-no-repeat')}
        href={href}
        target="_blank"
        style={{ backgroundImage: `url(${mbSrc})` }}
      />
      <div
        className={classnames(
          'hidden w-full lg:w-auto space-x-5 text-center lg:text-left absolute top-[45rem] lg:top-[455px] left-0 lg:left-1/2',
          styles.ButtonGroup,
          {
            '!block': leftButton || rightButton,
          },
        )}
      >
        <Button
          btnType={ButtonType.Primary}
          className={classnames('hidden rounded-xl', { '!inline-block': leftButton })}
          target={'_blank'}
          href={leftButtonLink}
        >
          {leftButton}
        </Button>
        <Button
          btnType={ButtonType.Primary}
          className={classnames('hidden rounded-xl', { '!inline-block': rightButton })}
          ghost
          target={'_blank'}
          href={rightButtonLink}
        >
          {rightButton}
        </Button>
      </div>
    </div>
  )
}

export default BannerImage
