// extracted by mini-css-extract-plugin
export var Carousel = "index-module--Carousel--3X2Oh";
export var brandModal = "index-module--brandModal--W+knR";
export var index_0 = "index-module--index_0--cHJY7";
export var index_1 = "index-module--index_1---++TJ";
export var mask = "index-module--mask--8JEVj";
export var move1 = "index-module--move1--bPo9B";
export var move2 = "index-module--move2--lq0M7";
export var move3 = "index-module--move3--6Wz5I";
export var move4 = "index-module--move4--ih98l";
export var slider = "index-module--slider--Wf2Pj";
export var whiteMask = "index-module--whiteMask--e0FBK";
export var wrap = "index-module--wrap--dovJk";