// extracted by mini-css-extract-plugin
export var bg_number = "index-module--bg_number--Pa7Y6";
export var buttonsGroup = "index-module--buttonsGroup--EXkX7";
export var contentContainer = "index-module--contentContainer--RKfD6";
export var contentContainerMb = "index-module--contentContainerMb--jrxhv";
export var en_title = "index-module--en_title--fJPHE";
export var list = "index-module--list--0PN4c";
export var listItem = "index-module--listItem--l47Oy";
export var logo = "index-module--logo--J80VS";
export var mainContent = "index-module--mainContent--oj2Q1";
export var next = "index-module--next--Vxz7z";
export var title = "index-module--title--3zS7x";
export var titleAll = "index-module--titleAll--syoj+";
export var titleContent = "index-module--titleContent--XoUbe";