import { Languages } from 'i18n/config'
import * as styles from './index.module.less'
import pc01 from './img/pc01.png'
import pc02 from './img/pc02.png'
import pc03 from './img/pc03.png'
import pc04 from './img/pc04.png'

import mb01 from './img/mb01.png'
import mb02 from './img/mb02.png'
import mb03 from './img/mb03.png'
import mb04 from './img/mb04.png'

const text = [
  {
    [Languages.EN]: {
      text: 'TMT',
      desc: 'E-commerce, Entertainment, Gaming',
    },
    [Languages.CN]: {
      text: '互联网 TMT',
      desc: '流量突围、持续发展破局',
      alt: '互联网行业数字化方案',
    },
  },
  {
    [Languages.EN]: {
      text: 'Finance',
      desc: 'Banking, Securities & Fund, Insurance',
    },
    [Languages.CN]: {
      text: '金融业 Finance',
      desc: '合规安全、经营增质提效',
      alt: '金融行业数字化方案',
    },
  },
  {
    [Languages.EN]: {
      text: 'CPG & Retail',
      desc: 'Apparel, Catering, Retail',
    },
    [Languages.CN]: {
      text: '品牌零售 CPG & Retail',
      desc: '全域布局、业务增长新范式',
      alt: '品牌零售数字化方案',
    },
  },
  {
    [Languages.EN]: {
      text: 'Enterprises',
      desc: 'Automobiles, Media, Healthcare',
    },
    [Languages.CN]: {
      text: '企业级 Enterprises',
      desc: '聚焦业务价值、高质量成长',
      alt: '企业级数字化方案',
    },
  },
]

const industryList = [
  {
    logo: pc03,
    logo_mb: mb03,
    style: styles.bannerBottom03,
    details: [
      {
        text: '互联网金融',
        link: 'https://www.sensorsdata.cn/solutions/finance.html',
      },
      {
        text: '社交文娱',
        link: 'https://www.sensorsdata.cn/solutions/entertainment.html',
      },
      {
        text: '电子商务',
        link: 'https://www.sensorsdata.cn/solutions/ecommerce.html',
      },
      {
        text: '企业服务',
        link: 'https://www.sensorsdata.cn/solutions/SaaS.html',
      },
      {
        text: '互联网',
        link: 'https://www.sensorsdata.cn/solutions/tool.html',
      },
      {
        text: '教育',
        link: 'https://www.sensorsdata.cn/solutions/education.html',
      },
      {
        text: '游戏',
        link: 'https://www.sensorsdata.cn/solutions/game.html',
      },
    ],
  },
  {
    logo: pc01,
    logo_mb: mb01,
    style: styles.bannerBottom01,
    details: [
      {
        text: '银行',
        link: 'https://www.sensorsdata.cn/solutions/bank.html',
      },
      {
        text: '证券基金',
        link: 'https://www.sensorsdata.cn/solutions/security.html',
      },
      {
        text: '保险',
        link: 'https://www.sensorsdata.cn/solutions/insurance.html',
      },
    ],
  },
  {
    logo: pc02,
    logo_mb: mb02,
    style: styles.bannerBottom02,
    details: [
      {
        text: '品牌零售',
        link: 'https://www.sensorsdata.cn/solutions/retail.html',
      },
    ],
  },
  {
    logo: pc04,
    logo_mb: mb04,
    style: styles.bannerBottom04,
    details: [
      {
        text: '汽车',
        link: 'https://www.sensorsdata.cn/solutions/automotive.html',
      },
      {
        text: '融合媒体',
        link: 'https://www.sensorsdata.cn/solutions/iptv.html',
      },
      {
        text: '医疗服务',
        link: 'https://www.sensorsdata.cn/solutions/healthCare.html',
      },
    ],
  },
]

interface GetIndustryListProps {
  lang?: Languages
}
type GetIndustryListFn = (props: GetIndustryListProps) => any
export const getIndustryList: GetIndustryListFn = ({ lang = Languages.CN }) => {
  const res = industryList.map((item, index) => ({
    ...item,
    ...text[index][lang],
  }))

  return res
}
