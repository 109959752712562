// extracted by mini-css-extract-plugin
export var Home = "index-module--Home--0Lt1w";
export var bannerSwiperEn = "index-module--bannerSwiperEn--KLk8n";
export var bigDataContent = "index-module--bigDataContent--fkNSh";
export var bigdataBanner = "index-module--bigdataBanner--STIAS";
export var digitalOperation = "index-module--digitalOperation--jg76k";
export var digitalOperationContents = "index-module--digitalOperationContents--uvdSf";
export var index_banner = "index-module--index_banner--fBDY1";
export var industryChoiceMainContent = "index-module--industryChoiceMainContent--kfdUj";
export var industryTrustChoice = "index-module--industryTrustChoice--0NQZv";
export var mainSection = "index-module--mainSection--Snzic";
export var products = "index-module--products--h7Z9y";
export var researchAndShare = "index-module--researchAndShare--xgoNT";
export var sensorsQualications = "index-module--sensorsQualications--k3-yS";
export var serviceAndProducts = "index-module--serviceAndProducts--KKLd4";
export var serviceAndProductsContent = "index-module--serviceAndProductsContent--EBKnx";
export var third_banner = "index-module--third_banner--4N6M3";